import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import Modal from 'react-bootstrap/Modal';
import get from 'lodash/get';
import has from 'lodash/has';
import camelCase from 'lodash/camelCase';
import moment from 'moment';
import cx from 'clsx';
import { InView } from 'react-intersection-observer';
import { connect } from 'react-redux';
import sidekick from '@last-rev/contentful-sidekick-util';
import styles from './PageCourseTopic.module.scss';
import PageCourseTopicPropTypes from './PageCourseTopicPropTypes';
import CourseFinishedPopup from '../CourseFinishedPopup';
import RichTextParser from '../RichTextParser';
import SocialLinks from '../SocialLinks';
import {
  completeCourse,
  completeTopicForCourse,
  setViewedCompletiondMessageForCourse
} from '../../redux/modules/courses';
import getThemeClass from '../../utils/getThemeClass';

const mapStateToProps = ({ user, courses }) => ({
  loggedIn: !!user.id,
  userLoaded: user.loaded,
  completedCourses: courses.completed,
  completedCoursesLoaded: courses.loaded
});

const mapDispatchToProps = {
  markTopicAsCompleted: completeTopicForCourse,
  markCourseAsCompleted: completeCourse,
  markCompletionMessageShown: setViewedCompletiondMessageForCourse
};

const collectLinks = (node, entriesLinks = []) => {
  const {
    data: { target },
    content
  } = node;

  if (content?.length) {
    content.forEach((item) => collectLinks(item, entriesLinks));
  }

  if (target?._contentTypeId === 'elementLink' && target?.destinationType === 'Set Anchor Tag') {
    entriesLinks.push({ ...target, href: `#${target?.linkText?.toLowerCase()?.replace(/ /g, '-')}` });
  } else if (target?._contentTypeId === 'layoutSection') {
    target.columns.forEach((column) => {
      if (column?._contentTypeId === 'elementLink' && column?.destinationType === 'Set Anchor Tag') {
        entriesLinks.push({ ...column, href: `#${column?.linkText?.toLowerCase()?.replace(/ /g, '-')}` });
      }
    });
  }
  return entriesLinks;
};

function parseRichTextField(richText) {
  if (!richText) return [];
  return collectLinks(richText);
}

function PageCourseTopic({
  _id,
  /* _href,
  _as,
  _contentTypeId, */
  eyebrow,
  name,
  description,
  publishDate,
  readingTime,
  content /* seo */,
  course,
  // redux state
  loggedIn,
  userLoaded,
  completedCourses,
  completedCoursesLoaded,
  // redux actions
  markTopicAsCompleted,
  markCompletedOnScrollToBottom,
  markCompletionMessageShown,
  showToC
}) {
  const [enableCompletionPopup, setEnableCompletionPopup] = useState(false);
  const [url, setUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [scrollClass, setScrollClass] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const modalShown = useRef(false);
  const sectionNavRef = useRef(null);
  const { t } = useTranslation();
  const triggerCompletionMessage = useCallback(() => {
    setEnableCompletionPopup(true);
    markCompletionMessageShown(get(course, '_id'));
  }, [course, markCompletionMessageShown]);

  useEffect(() => {
    if (course && completedCoursesLoaded) {
      if (has(completedCourses, course._id)) {
        if (!get(completedCourses, `['${course._id}'].viewedCompletionMessage`, false)) {
          triggerCompletionMessage();
        }
      }
    }
  }, [completedCoursesLoaded, completedCourses, triggerCompletionMessage, course]);

  useEffect(() => {
    setUrl(window.location.href);
  }, [url]);

  const bottomViewChange = useCallback(
    (inView, _entry) => {
      if (!inView) {
        setShowModal(false);
      } else if (loggedIn) {
        markTopicAsCompleted(_id, get(course, '_id'), get(course, 'topics'));
      } else if (!modalShown.current) {
        setShowModal(true);
        modalShown.current = true;
      }
    },
    [_id, course, loggedIn, markTopicAsCompleted]
  );

  const themeClassName = () =>
    course ? `theme-${getThemeClass({ _contentTypeId: course._contentTypeId, category: course.category })}` : null;

  const anchorTags = useMemo(() => parseRichTextField(content), [content]);

  useEffect(() => {
    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setCurrentSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            sectionNavRef?.current?.classList.add(styles.footerInView);
          } else {
            sectionNavRef?.current?.classList.remove(styles.footerInView);
          }
        });
      },
      { threshold: 0.25 }
    );

    const sections = document.querySelectorAll('section + span[id]');
    const footer = document.querySelector('footer');

    sections.forEach((section) => {
      sectionObserver.observe(section);
    });

    observer.observe(footer);

    const handleScroll = () => {
      if (window.pageYOffset >= 160) {
        setScrollClass(true);
      } else {
        setScrollClass(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      sections.forEach((section) => {
        sectionObserver.unobserve(section);
      });

      observer.unobserve(footer);

      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div
        data-testid="PageCourseTopic"
        className={styles.pageCourseTopic}
        {...sidekick(_id, null, null, 'Page - Course Topic')}
        itemScope
        itemType="http://schema.org/Article">
        <div className={styles.layout}>
          <div className={cx(styles.sideNav, !(showToC && anchorTags) && styles.noToc)}>
            {showToC && anchorTags ? (
              <nav className={scrollClass ? `section-nav ${styles.scrolling}` : 'section-nav'} ref={sectionNavRef}>
                <ul>
                  {anchorTags.map((anchor) => (
                    <li
                      key={camelCase(anchor._id)}
                      className={anchor.href === `#${currentSection}` ? styles.active : null}>
                      <a href={anchor.href} data-uxa-log={`course_module_topic_${anchor.linkText.toLowerCase().replace(/\s+/g, '_')}`} data-uxa-interactions="click shown">{anchor.linkText}</a>
                    </li>
                  ))}
                </ul>
              </nav>
            ) : null}
          </div>

          <article className={!(showToC && anchorTags) && styles.noToc}>
            {eyebrow ? (
              <p className={styles.eyebrow} data-testid="PageCourseTopic-eyebrow" {...sidekick(_id, 'eyebrow')}>
                {eyebrow}
              </p>
            ) : null}

            {name ? (
              <h1 className={styles.name} data-testid="PageCourseTopic-name" {...sidekick(_id, 'name')} itemProp="name">
                {name}
              </h1>
            ) : null}

            {description && description?.trim() !== '' ? (
              <h2
                className={styles.description}
                data-testid="PageCourseTopic-description"
                {...sidekick(_id, 'description')}>
                {description}
              </h2>
            ) : null}

            <div className="d-flex justify-content-between mb-3 pb-xs-3 flex-wrap">
              {publishDate || readingTime ? (
                <div className="d-flex">
                  {publishDate ? (
                    <>
                      <p className="mr-1">Updated</p>
                      <time
                        data-testid="PageCourseTopic-publishDate"
                        {...sidekick(_id, 'publishDate')}
                        itemProp="datePublished"
                        dateTime={publishDate}>
                        {moment(publishDate, 'dddd LL').format('MMM Do, YYYY')}
                      </time>
                    </>
                  ) : null}
                  {readingTime ? (
                    <p
                      className={cx(styles.readingTime, publishDate && styles.hasDate)}
                      data-testid="PageCourseTopic-readingTime"
                      {...sidekick(_id, 'readingTime')}
                      itemProp="timeRequired">
                      {readingTime} {t('common:readingTime')}
                    </p>
                  ) : null}
                </div>
              ) : null}

              {url ? <SocialLinks url={url} /> : null}
            </div>

            {content ? (
              <section className={cx(styles.contentSection, themeClassName())} data-testid="PageCourseTopic-content">
                <RichTextParser document={content} />
              </section>
            ) : null}
          </article>
        </div>
      </div>
      {course && markCompletedOnScrollToBottom ? (
        <>
          <InView skip={!userLoaded} onChange={(inView, entry) => bottomViewChange(inView, entry)} />
          {enableCompletionPopup && (
            <CourseFinishedPopup
              _id={course._id}
              _contentTypeId={course._contentTypeId}
              image={course.courseCompleteImage}
              headerText={course.courseCompleteHeaderText}
              bodyText={course.courseCompleteBody}
              cta={course.courseCompleteCta}
              showIsCourseFinished={enableCompletionPopup}
              className={themeClassName()}
            />
          )}
        </>
      ) : null}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop
        scrollable
        centered
        size="lg"
        className={styles.modalDialog}>
        <Modal.Header closeButton className={styles.signInPromptModalHeader} />
        <Modal.Body className={styles.signInPromptModalBody}>
          <div className={styles.signInPromptWrap}>
            <div className={styles.signInPromptHeaderText}>{t('common:textSigninModalIntro')}</div>

            <div className={styles.signInPromptCtaWrap}>
              <a className={styles.signInPromptCta} href={`https://www.dropbox.com/login?cont=${url}`}>
                {t('common:ctaTextSignIn')}
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

PageCourseTopic.propTypes = {
  ...PageCourseTopicPropTypes,
  markTopicAsCompleted: PropTypes.func.isRequired,
  markCompletedOnScrollToBottom: PropTypes.bool,
  showToC: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.object
};

PageCourseTopic.defaultProps = {
  markCompletedOnScrollToBottom: false,
  content: null,
  showToC: false
};

export default connect(mapStateToProps, mapDispatchToProps)(PageCourseTopic);
